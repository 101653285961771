import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { navigate } from '@reach/router';

import Layout from '../components/Layout';

import './index.css';
import Section from '../components/Section';
import Testimonials from '../components/Testimonial/Testimonials';
import WaitlistFull from '../components/WaitlistFull';
import Totals from '../components/Totals';
import MakersWithBio from '../components/Makers/MakersWithBio';
import CommunityImgs from '../components/CommunityImgs';
import Plans from '../components/Plans';
import Callout from '../components/Callout';
import Button from '../components/Button';
import { IfClosed, IfOpen } from '../components/OpenFlags';
import Header from '../components/Header';

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "jon-peters/hero-dark.jpg" }) {
      data: childImageSharp {
        fixed(width: 1800, quality: 80) {
          src
        }
      }
    }
    headshot: file(relativePath: { eq: "jon-peters/headshot-lg.jpg" }) {
      data: childImageSharp {
        fixed(width: 800, quality: 80) {
          src
        }
      }
    }
  }
`;

class JonPetersPage extends React.Component {
  render() {
    const maker = 'peters';
    const { data } = this.props;
    const heroImg = get(data, 'hero.data.fixed.src');
    // const headshotImg = get(data, 'headshot.data.fixed.src');
    const renderThankYou = this.props.location.search === '?thankyou=true';

    return (
      <Layout pageTitle="Jon Peters">
        <div
          className="bg-black items-center bg-no-repeat"
          style={{
            backgroundImage: `url(${heroImg})`,
            backgroundPosition: '25% 20%',
            backgroundAttachment: 'local',
            backgroundSize: 'cover',
          }}
        >
          <Header maker={maker} />

          <div className="container mx-auto">
            <div className="py-32 md:flex">
              <div className="md:w-1/2" />
              <div className="md:w-1/2 px-8">
                <h1 className="text-grey-lighter text-5xl mb-2 antialiased uppercase">
                  Jon Peters
                </h1>
                <h2 className="text-grey-light text-md mb-8 font-normal antialiased uppercase">
                  Master Craftsman &amp; Artist
                </h2>
                <p className="text-white leading-normal tracking-normal mb-4 text-lg font-bold">
                  Join my Makers Mob and get inspired.
                </p>
                <p className="text-white leading-normal tracking-normal mb-8">
                  Whether you're a beginner, a practical D.I.Y’er, or a
                  professional craftsman, my Makers Mob will provide a how-to
                  guide to great projects. I will show you the tools, the
                  techniques, and the tricks of the trade to bring woodworking
                  and design to your home. Enjoy and get inspired!
                </p>

                <IfOpen>
                  <Button onClick={e => navigate(`/plans?ref=${maker}`)}>
                    Join Makers Mob
                  </Button>
                </IfOpen>
              </div>
            </div>
          </div>
        </div>

        <div>
          <IfClosed>
            <WaitlistFull
              redirectUrl="https://www.joinmakersmob.com/jon-peters/?thankyou=true"
              listId="awlist5217591"
              subscribed={renderThankYou}
              makersName="Jon Peters"
            />
          </IfClosed>
        </div>

        <div>
          <div className="text-2xl text-center text-grey-darkest font-bold mb-12 mt-24">
            Every All Access Makers Mob Membership Includes:
          </div>
          <Totals />
        </div>

        <Callout variation="workbench" maker={maker} />

        <Section title="What Our Members are Saying..." type="dark">
          <Testimonials />
        </Section>

        <Section title="Plans, Material Lists, Cut Lists &amp; More">
          <Plans />
        </Section>

        <Section title="Exclusive Private Member's Community" type="dark">
          <div className="mb-32">
            <div className="text-grey-dark mb-8 -mt-6 text-xl text-center">
              Probably the Most Valuable Aspect of the School...
            </div>
            <div className="text-grey-light md:w-2/3 mx-4 md:mx-auto leading-normal">
              A PRIVATE Group, where THOUSANDS of Woodworkers from all over the
              World Get Together and Inspire each other! Also, Every week we
              host a LIVE Hangout where you can ask questions and interact with
              1000's of fellow woodworkers.
            </div>

            <CommunityImgs />
          </div>
        </Section>

        <div className="mt-24">
          <div className="text-2xl text-center text-grey-darkest font-bold mb-8">
            Meet the Makers
          </div>

          <div className="container mx-auto">
            <MakersWithBio defaultMaker="peters" />
          </div>
        </div>

        <Callout variation="peters" maker={maker} />

        <script
          type="text/javascript"
          src="https://app.paykickstart.com/tracking-script"
        />
      </Layout>
    );
  }
}

export default JonPetersPage;
